<template>
    <ResultDetails />
  </template>
  
  <script>
    import ResultDetails from '../components/ResultDetails'
  
    export default {
      name: 'Details-view',
  
      components: {
        ResultDetails,
      },
    }
  </script>
  