<template>
  <Homepage />
</template>

<script>
  import Homepage from '../components/Homepage'

  export default {
    name: 'Home-view',

    components: {
      Homepage,
    },
  }
</script>
